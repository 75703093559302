import React from "react";
import { graphql } from "gatsby";
import ProductPanel from "../components/ProductPanel/ProductPanel";
import { transformEdgesNodes } from "../components/utils";
import GenericContentIndexPage from "./generic/genericContentIndexTemplate";
import withLayoutAndData from "./generic/withLayoutAndData";

export const ProductsIndexPage = ({ page }) =>
	<GenericContentIndexPage page={page}
		showAd
		ContentItemComponent={ProductPanel} />;

export const pageQuery = graphql`
	query ProductsIndexPageQuery {
		markdownRemark(frontmatter: { templateKey: { eq: "products-index-page" } }) {
			html
			frontmatter {
        		title
        		heading
			}
			fields {
				slug
			}
			...RemoteBannerImage
		}
		items: allMarkdownRemark(
			sort: {order: [DESC, DESC], fields: [frontmatter___featuredpost, frontmatter___date]}
			filter: { frontmatter: { templateKey: { eq: "product-page" } } }
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						description
						showNew
					}
					...RemoteBannerImageSmall
				}
			}
		}
	}`;

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			...data.markdownRemark.fields,
			content: data.markdownRemark.html,
			items: transformEdgesNodes(data.items),
		}
	})
)(ProductsIndexPage);
