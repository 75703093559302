import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { Heading, Box, Button } from "grommet";
import { Shop } from "grommet-icons";
import NavLink from "../Link/NavLink";
import Image from "../Image/Image";
import { border, breakpoint } from "../../theme";
import getIsNewItem from "../../common/getIsNewItem";

const Container = styled(Box)`
	justify-content: space-around;
	align-items: center;
	margin-bottom: 40px;
	margin-left: 20px;
	width: 280px;
	min-height: 260px;
`;

const ProductHeading = styled(Heading)`
	width: 210px;
	text-align: center;
	min-height: 100px;
`;

const EntryImage = styled(Image)`
	flex-grow: 1;
	min-width: 200px;
	min-height: 160px;
	max-height: 160px;

	${breakpoint("phone")`
		width: 140px;
	`}
`;

const Separator = styled.div`
	width: 90%;
	height: 1px;
	${border({ style: "dashed" })`bottom`}
`;

const DetailsButton = styled(Button)`
	border: none;
`;

const ProductPanel = ({ item, buttonTitle }) => {
	return <Container elevation="medium" gap="large" pad="medium" round="small">

		<NavLink to={item.slug}>
			<ProductHeading level="3" size="medium">
				{item.title}
			</ProductHeading>
		</NavLink>

		<NavLink to={item.slug}>
			<EntryImage
				image={item.image}
				alt={item.alt || item.title}
        showNew={getIsNewItem(item)}
			/>
		</NavLink>

		<Separator />

		<DetailsButton
			primary
			reverse
			color="brand-gradient-dark"
			icon={<Shop />}
			label={buttonTitle || "לפרטים"}
			onClick={() => navigate(item.slug)} />
	</Container>;
};

export default ProductPanel;

